// https://vuex.vuejs.org/en/state.html

export default {
    status: '',
    token: localStorage.getItem('wksgn_token') || '',
    user: JSON.parse(localStorage.getItem('wksgn_user') || "{}"),
    companyconfig: JSON.parse(localStorage.getItem('wksgn_companyconfig') || "{}"),
    color: 'success',
    snackbar: false,
    msg: "",
    offlinedata: JSON.parse(localStorage.getItem('wksgn_offlinedata') || '{}'),
    online: true
}