import { stat } from "fs";

// https://vuex.vuejs.org/en/mutations.html

export default {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, params) {
        state.status = 'success'
        state.token = params.token
        state.user = params.user
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
    },
    snack(state, params) {
        state.msg = params.msg;
        state.color = params.color;
        state.snackbar = true;
        setTimeout(() => {
            state.snackbar = false;
        }, 4000);
    },
    online(state, params) {
        state.online = params;
    },
    addAnlageOffline(state, params) {
        let data = state.offlinedata;
        if (!data) {
            data = {};
        }
        if (!data.offlineanlagen) {
            data.offlineanlagen = [];
        }
        data.offlineanlagen.push(params);
        localStorage.setItem('offlinedata', JSON.stringify(data));
    },
    addWartungOffline(state, params) {
        let data = state.offlinedata;
        if (!data) {
            data = {};
        }
        if (!data.offlinewartungen) {
            data.offlinewartungen = [];
        }
        data.offlinewartungen.push(params);
        localStorage.setItem('offlinedata', JSON.stringify(data));
    },
    setAnlagenOffline(state, params) {
        let data = state.offlinedata;
        if (!data) {
            data = {};
        }
        data.offlineanlagen = params;
        localStorage.setItem('offlinedata', JSON.stringify(data));
    },
    setWartungenOffline(state, params) {
        let data = state.offlinedata;
        if (!data) {
            data = {};
        }
        data.offlinewartungen = params;
        localStorage.setItem('offlinedata', JSON.stringify(data));
    },
    setOfflineData(state, params) {
        state.offlinedata = params;
        localStorage.setItem('offlinedata', JSON.stringify(params));
    }

}