/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store'

// Routes
import paths from './paths'

function route(path, view, name, meta) {
    return {
        name: name || view,
        path,
        meta,
        component: (resolve) =>
            import(
                `@/views/${view}.vue`
            ).then(resolve)
    }
}

Vue.use(Router)

//TODO add role to routes-> security
const router = new Router({
    mode: 'history',
    routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([
        { path: '*', redirect: '/' }
    ]),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.meta.componentId) {
        const componentids = store.getters.companyconfig.componentids;
        if (componentids.indexOf(to.meta.componentId) == -1) {
            next('/');
            return
        }
    }
    if (to.meta.roles) {
        const role = store.getters.user.role;
        if (to.meta.roles.indexOf(role) === -1) {
            next('/');
            return
        }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return
        }
        next('/login');
    } else {
        next();
    }
});

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
})

Vue.use(Meta)

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
    Vue.use(VueAnalytics, {
        id: process.env.GOOGLE_ANALYTICS,
        router,
        autoTracking: {
            page: process.env.NODE_ENV !== 'development'
        }
    })
}

export default router