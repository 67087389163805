<template>
  <v-content>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <v-footer color="tertiary" absolute>
      <span class="white--text">&copy;2021 Copyright - WerksGenie</span>
      <v-divider style="border-color: rgba(0,0,0,0);"></v-divider>
      <span class="white--text">Impressum</span>
    </v-footer>
  </v-content>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "WerksGenie. Das Online Handwerker Tool"
    };
  }
};
</script>

<style>
#core-view {
  padding: 0 10px 50px 10px;
}
</style>

