import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify)
Vue.use(DatetimePicker)

const opts = {
    theme: {
        options: {
          customProperties: true
        },
        themes: {
          light: {
            primary: '#4e8dba',
            secondary: '#db7200',
            tertiary: '#062199',
            darkprimary: '#6C7D80',
            tabscolor: '#7c7c7c',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
}

export default new Vuetify(opts)