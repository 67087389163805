import Vue from 'vue'

// Lib imports
import axios from 'axios'

let development = process.env.NODE_ENV !== 'production'

const base = axios.create({
    baseURL: development ? 'http://localhost/payman/api' : 'https://app.werksgenie.de/api', //'http://localhost:8080/api' : 'https://mtain.de/api',
})

base.defaults.serverURL = development ? 'http://localhost/payman' : 'https://app.werksgenie.de';
const token = localStorage.getItem('wksgn_token');
if (token) {
    base.defaults.headers.common['Authorization'] = token
}

Vue.prototype.$http = base