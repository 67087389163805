import Vue from 'vue'
// Lib imports
import moment from 'moment'

moment.locale('de');

Vue.prototype.$moment = moment;

Vue.prototype.$formatDate = function(value) {
    if (!value) {
        return "";
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.format("DD.MM.YYYY");
    } else {
        return "";
    }
}

Vue.prototype.$formatDateTime = function(value) {
    if (!value) {
        return "";
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.format("DD.MM.YYYY HH:mm");
    } else {
        return "";
    }
}

Vue.prototype.$diffInDays = function(date1, date2) {
    if (!date1 || !date2) {
        return "";
    }
    const a = moment(date2);
    const b = moment(date1);
    return a.diff(b, 'days') + 1
}


Vue.prototype.$formatDateTimeISO = function(value) {
    if (!value) {
        return "";
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.format("YYYY-MM-DD HH:mm");
    } else {
        return "";
    }
}

Vue.prototype.$formatDateISO = function(value) {
    if (!value) {
        return "";
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.format("YYYY-MM-DD");
    } else {
        return "";
    }
}

Vue.prototype.$formatDateWithDay = function(value) {
    if (!value) {
        return "";
    }
    const date = moment(value);
    if (date.isValid()) {
        return date.format("ddd DD.MM.YYYY");
    } else {
        return "-";
    }
}