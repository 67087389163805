export default [{
        path: '',
        view: 'Dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/wartungen',
        view: 'Wartungen',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customers',
        name: 'Kunden',
        view: 'Customers',
        meta: {
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/schichtplanung',
        name: 'schichtplanung',
        view: 'Schichtplanung',
        meta: {
            componentId:'sp32582',
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        view: 'TeamCalendar',
        meta: {
            componentId: "tc48762",
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/employees',
        name: 'employees',
        view: 'Employees',
        meta: {
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/equipments',
        name: 'equipments',
        view: 'Equipments',
        meta: {
            componentId:'gm40982',
            requiresAuth: true
        }
    },
    {
        path: '/equipmentsworker',
        name: 'equipmentsworker',
        view: 'Equipmentsworker',
        meta: {
            componentId:'gm40982',
            requiresAuth: true,
            roles: ["worker"]
        }
    },
    {
        path: '/assignments',
        name: 'assignments',
        view: 'Assignments',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/arbeitsnachweise',
        name: 'arbeitsnachweise',
        view: 'Arbeitsnachweise',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/assignment/:id',
        view: 'Assignment',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/anlagewartung/:mode/:id/:wid/:blockid/:offline?',
        view: 'AnlageWartung',
        meta: {
            componentId:'wt97323',
            requiresAuth: true
        }
    },
    {
        path: '/anlage/:mode/:id/:blockid?/:customerid?/:offline?',
        view: 'Anlage',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/block/:mode/:id',
        view: 'Block',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/anlagehistory/:id',
        view: 'AnlageHistory',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blockhistory/:id',
        view: 'BlockHistory',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/wartungsdetails/:mode/:wid/:blockid/:assignmentid?',
        view: 'WartungsDetails',
        meta: {
            componentId:'wt97323',
            requiresAuth: true
        }
    },
    {
        path: '/customer/:id',
        view: 'Customer',
        meta: {
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/employee/:id',
        view: 'Employee',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/arbeitsnachweis/:mode/:assignmentid/:id?',
        view: 'Arbeitsnachweis',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/administration',
        view: 'Administration',
        meta: {
            requiresAuth: true,
            roles: ["admin", "pl"]
        }
    },
    {
        path: '/sync',
        view: 'Synchronisation',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        view: 'Login'
    }
]