export default {
    login({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('auth_request');
            let formData = new FormData();
            formData.append("email", params.email);
            formData.append("password", params.password);
            /*  const data = {
                 "email": params.email,
                 "password": params.password
             }; */
            let http = params.axios;
            const url = http.defaults.baseURL + "/login.php";
            //http.post(url, data)
            http({
                    method: "post",
                    url: url,
                    data: formData
                })
                .then(response => {
                    if (response.status === 200) {
                        /*  if (response.data === "wrongCredentials") {
                             resolve({ wrongDetails: true });
                             return;
                         }
                         if (response.data === "userNotActive") {
                             resolve({ userNotActive: true });
                             return;
                         }
                         if (response.data === "userNotFound") {
                             resolve({ userNotFound: true });
                             return;
                         } */
                        if (response.data.wrongDetails) {
                            resolve({ wrongDetails: true });
                            return;
                        }
                        if (response.data.userNotActive) {
                            resolve({ userNotActive: true });
                            return;
                        }
                        if (response.data.userNotFound) {
                            resolve({ userNotFound: true });
                            return;
                        }
                        const token = response.data.token;
                        const user = response.data.user;
                        const configString = response.data.companyconfig;
                        const companyconfig = {};
                        Object.entries(configString).forEach(entry => {
                            let item = entry[1];
                            item = typeof item !== "string" ?
                                JSON.stringify(item) :
                                item;

                            try {
                                item = JSON.parse(item);
                            } catch (e) {}
                            companyconfig[entry[0]] = item;
                        })
                        localStorage.setItem('wksgn_token', token);
                        localStorage.setItem('wksgn_user', JSON.stringify(user));
                        localStorage.setItem('wksgn_companyconfig', JSON.stringify(companyconfig));
                        http.defaults.headers.common['Authorization'] = token;
                        commit('auth_success', { token, user });
                        resolve({});

                        /*
                        const token = response.data.accessToken;
                        if (!token) {
                            resolve({ wrongDetails: true });
                            return;
                        }
                        const user = {
                            id: response.data.id,
                            email: response.data.email,
                            name: response.data.name,
                            companyid: response.data.companyid,
                            role: response.data.roles[0]
                        };
                        localStorage.setItem('token', token);
                        localStorage.setItem('user', JSON.stringify(user));
                        //localStorage.setItem('companyconfig', JSON.stringify(companyconfig));
                        http.defaults.headers.common['Authorization'] = token;
                        commit('auth_success', { token, user });
                        resolve({});
                        */
                    }
                })
                .catch(error => {
                    resolve({ userNotFound: true });
                });
        })
    },

    logout({ commit }, params) {
        let http = params.axios;
        return new Promise((resolve, reject) => {
            commit('logout');
            localStorage.removeItem('wksgn_token');
            localStorage.removeItem('wksgn_user');
            localStorage.removeItem('wksgn_companyconfig');
            delete http.defaults.headers.common['Authorization'];
            resolve();
        })
    },

    online({ commit }, params) {
        let http = params.axios;
        return new Promise((resolve, reject) => {
            commit('online', params);
        })
    },

    snackbar({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('snack', params);
        })
    },

    addAnlageOffline({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('addAnlageOffline', params);
        })
    },

    addWartungOffline({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('addWartungOffline', params);
        })
    },

    setAnlagenOffline({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setAnlagenOffline', params);
        })
    },

    setWartungenOffline({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setWartungenOffline', params);
        })
    },

    setOfflineData({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setOfflineData', params);
        })
    }
}