<template>
  <v-app id="inspire">
    <core-drawer v-if="path != '/login'" />
     <core-appbar v-if="path != '/login'" />
    <core-view />
    <v-snackbar v-model="snackbar" right :color="color" bottom>
      <v-icon color="white" large class="mr-3">mdi-bell-plus</v-icon>
      {{ msg }}
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null
  }),
  computed: {
    path() {
      return this.$route.path;
    },
    color() {
      return this.$store.getters.color;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
    msg() {
      return this.$store.getters.msg;
    }
  }
};
</script>


<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>