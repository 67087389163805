<template>
  <v-app-bar
    v-if="path != '/login'"
    app
    height="67"
    color="secondary"
    dark
    id="core-appbar"
  >
    <v-app-bar-nav-icon
      v-if="!hideDrawerToggle"
      @click.stop="onDrawerClick"
    ></v-app-bar-nav-icon>
    <v-autocomplete
      class="ml-8 search-input"
      v-if="role === 'admin' || role === 'pl'"
      :search-input.sync="search"
      :items="items"
      :loading="isLoading"
      clearable
      filled
      hide-details
      hide-selected
      hide-no-data
      no-filter
      item-text="name"
      label="Suche nach Kunden, Mitarbeiter, Anlagen, Wartungsblöcke, Aufträge"
      solo-inverted
      flat
      return-object
      @change="onClick"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title
            >Suchen Sie im gesamten Unternehmen</v-list-item-title
          >
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <!-- <v-list-item-avatar
          color="indigo"
          class="headline font-weight-light white--text"
        >{{ item.name.charAt(0) }}</v-list-item-avatar>-->
        <v-list-item-icon>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.label"></v-list-item-title>
          <v-list-item-subtitle v-text="item.small"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-badge style="margin-left: 10px" :color="onlineColor">
      <template v-slot:badge>
        <v-icon>mdi-wifi</v-icon>
      </template>
    </v-badge>
    <v-spacer></v-spacer>
    <v-btn
      small
      fab
      color="green"
      @click="$router.push('/arbeitsnachweis/new/-1')"
    >
      <v-icon>mdi-playlist-plus</v-icon>
    </v-btn>

    <!-- <v-badge overlap color="red">
      <template v-slot:badge>
        <span v-if="count > 0">{{ count }}</span>
      </template>
      <v-icon color="white">mdi-bell</v-icon>
    </v-badge>
    -->
    <!--<v-menu left bottom>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          {{username}}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="logout()">
          <v-list-item-title>Ausloggen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>-->
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="primary" size="40">
            <span class="white--text" style="font-size: 16px">{{
              username
            }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="primary">
              <span class="white--text" style="font-size: 16px">{{
                username
              }}</span>
            </v-avatar>
            <h3>{{ user.name }}</h3>
            <p class="caption mt-1">{{ user.email }}</p>
            <v-divider class="my-3"></v-divider>
            <v-btn
              @click="$router.push('/employee/' + user.id)"
              depressed
              rounded
              text
              >Mein Profil</v-btn
            >
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded @click="logout()" text>Ausloggen</v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    count: 2,
    isLoading: false,
    isClicked: false,
    onlineColor: "success",
    items: [],
    username: "",
    model: null,
    search: null,
    hideDrawerToggle: false,
  }),
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.hideDrawerToggle = true;
    }
    const user = (this.user = this.$store.getters.user);
    if (user && user.name) {
      this.username = user.name
        .split(" ")
        .map((n) => n[0])
        .join(".");
    } else {
      this.username = "n.A";
    }
    this.role = this.$store.getters.user.role;
    window.addEventListener("online", () => {
      this.onlineColor = "success";
      this.$store.dispatch("online", true);
    });
    window.addEventListener("offline", () => {
      this.onlineColor = "error";
      this.$store.dispatch("online", false);
    });
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
  watch: {
    search(val) {
      if (!val || val === "" || val.length < 3 || this.isClicked) {
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "search.php",
        data: formData,
      })
        .then((response) => {
          if (response.data && response.data.success) {
            this.items = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    onDrawerClick() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClick(obj) {
      this.isClicked = true;
      if (obj.cat === 1 && obj.id) {
        this.items = [];
        this.$router.push("/employee/" + obj.id);
        this.isClicked = false;
      }
      if (obj.cat === 2 && obj.id) {
        this.items = [];
        this.$router.push("/anlage/edit/" + obj.id);
        this.isClicked = false;
      }
      if (obj.cat === 3 && obj.id) {
        this.items = [];
        this.$router.push("/customer/" + obj.id);
        this.isClicked = false;
      }
      if (obj.cat === 4 && obj.id) {
        this.items = [];
        this.$router.push("/block/edit/" + obj.id);
        this.isClicked = false;
      }
      if (obj.cat === 5 && obj.id) {
        this.items = [];
        this.$router.push("/assignment/" + obj.id);
        this.isClicked = false;
      }
    },
    logout() {
      let axios = this.$http;
      this.$store
        .dispatch("logout", { axios })
        .then((resp) => {
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
