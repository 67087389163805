<template>
  <v-navigation-drawer
    id="app-drawer"
    permanent
    :mini-variant="inputValue"
    app
    dark
    floating
    persistent
    width="260"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-img :src="logo"></v-img>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!inputValue" class="text-center"
          >Online Handwerker Tool</v-list-item-subtitle
        >
        <div class="version">Version 1.4.8</div>
      </v-list-item-content>
    </v-list-item>

    <v-divider />
    <v-list dense>
      <v-list-item
        v-for="(link, i) in filterdlinks"
        :key="i"
        :to="link.to"
        :class="subIsActive(link)"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-html="link.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="role === 'admin'"></v-divider>
      <v-list-item
        to="/administration"
        class="v-list-item"
        key="99"
        v-model="active"
        active-class="secondary"
        v-if="role === 'admin'"
      >
        <v-list-item-action>
          <v-icon>settings</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Administration</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-img class="ma-4 mt-9" max-width="220"  contain :src="logo2" v-if="logo2"></v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    logo: require("@/assets/logo2.png"),
    active: false,
    links: [
      {
        to: "/",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
      },
      {
        to: "/wartungen",
        icon: "mdi-format-list-checkbox",
        text: "Wartungen",
        roles: ["admin", "pl"],
        subs: ["wartungsdetails", "anlagewartung"],
        componentId: "wt97323",
      },
      {
        to: "/assignments",
        icon: "mdi-file-outline",
        subs: ["assignment"],
        text: "Aufträge",
      },
      {
        to: "/arbeitsnachweise",
        icon: "mdi-playlist-check",
        subs: ["arbeitsnachweis"],
        text: "Arbeitsnachweise",
      },
      {
        to: "/customers",
        icon: "people",
        text: "Kunden",
        subs: ["customer"],
        roles: ["admin", "pl"],
      },
      {
        to: "/schichtplanung",
        icon: "mdi-calendar",
        text: "Schichtplanung",
        componentId: "sp325823",
      },
      {
        to: "/equipments",
        icon: "mdi-toolbox",
        text: "Gerätemanagement",
        componentId: "gm40982",
      },
      {
        to: "/calendar",
        icon: "mdi-calendar",
        text: "Teamcalendar",
        componentId: "tc48762"
      },
      {
        to: "/employees",
        icon: "mdi-account",
        text: "Mitarbeiter",
        subs: ["employee"],
        roles: ["admin"],
      },
      /* {
        to: "/equipmentsworker",
        icon: "mdi-toolbox",
        text: "Gerätemanagement",
        roles: ["worker"]
      }, */
      /*  {
        to: "/sync",
        icon: "refresh",
        text: "Synchronisation",
      }, */
      /*  {
        to: "/administration",
        icon: "settings",
        text: "Administration",
        roles: ["admin", "pl"]
      } */
    ],
  }),
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.setDrawer(true);
    }
  },
  computed: {
    filterdlinks() {
      const role = this.$store.getters.user.role;
      const componentids = this.componentids;
      let result = [];
      this.links.forEach((link) => {
        if (link.componentId && componentids.length && componentids.indexOf(link.componentId) == -1) {
          return;
        }
        if (!link.roles) {
          result.push(link);
        } else {
          if (link.roles.includes(role)) {
            result.push(link);
          }
        }
      });
      return result;
    },
    logo2() {
      if (
        this.$store.getters.companyconfig &&
        this.$store.getters.companyconfig.logopath
      ) {
        return (
          this.$http.defaults.serverURL +
          "/uploads/" +
          this.$store.getters.companyconfig.logopath
        );
      } else {
        return null;
      }
    },
    componentids(){
      return  this.$store.getters.companyconfig.componentids;
    },
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    role() {
      return this.$store.getters.user.role;
    },
    path() {
      return this.$route.path;
    },
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return this.$t("Layout.View.items");
    },
  },

  methods: {
    subIsActive(input) {
      if (input.to === this.path) {
        return "secondary";
      }
      if (
        input &&
        input.subs &&
        input.subs.find((i) => {
          return this.path.indexOf(i) > -1;
        })
      ) {
        return "secondary";
      }
    },
    ...mapMutations("app", ["setDrawer"]),
  },
};
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }
}
</style>
