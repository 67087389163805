// https://vuex.vuejs.org/en/getters.html

export default {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    companyconfig: state => state.companyconfig,
    snackbar: state => state.snackbar,
    msg: state => state.msg,
    color: state => state.color,
    offlinedata: state => state.offlinedata,
    online: state => state.online
}