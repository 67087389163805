import Vue from 'vue'

const currencyFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

Vue.prototype.$uploadDocs = function(docs, id, type,folder) {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);
    formData.append("folder", folder || "");
    for (let i = 0; i < docs.length; i++) {
        let file = docs[i];
        formData.append("docs[" + i + "]", file);
    }
    return this.$http({
        method: "post",
        url: "uploadDocuments.php",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

Vue.prototype.$deleteDoc = function(id, path) {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("path", path);
    return this.$http({
        method: "post",
        url: "deleteDocument.php",
        data: formData
    })
}

Vue.prototype.$openDoc = function(item) {
    const url = this.$http.defaults.serverURL + "/" + item.path;
    window.open(url, "_blank");
}

Vue.prototype.$formatCurrency = function(number) {
    if (!number) {
        return "";
    }
    return currencyFormatter.format(number);
}


Vue.prototype.$isJson = function(item) {
    item = typeof item !== "string" ?
        JSON.stringify(item) :
        item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}