import Vue from 'vue'
import App from './App.vue'
import './plugins'

import './components'

import { sync } from 'vuex-router-sync'
import vuetify from '@/plugins/vuetify' // path to vuetify export


import router from '@/router'
import store from '@/store'

sync(store, router)

Vue.config.productionTip = false

new Vue({
    router,
    store,
	vuetify,
    render: h => h(App)
}).$mount('#app')
